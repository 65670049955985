import { useState, useEffect, useRef } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import AppointmentModal from "./appointmentmodal";
import axios from "axios";
import BaseUrl from "../../Api/baseurl";
import Cookies from "js-cookie";
import { FaUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
const Header = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [data, setData] = useState({ new_logo: "" });
  const [isLogin, setIsLogin] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [details, setDetails] = useState({
    image: "",
    name: "",
  });
  let Login = Cookies.get("patient_username");
  const handleTabClick = (tab) => {
    setActiveTab(window.location.pathname);
  };

  const active = window.location.pathname;

  const fetchData = async () => {
    const apiUrl = `${BaseUrl}clinic/logochange/`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const fetchDetail = async () => {
    const username = Cookies.get("patient_username");
    const url = `${BaseUrl}clinic/patient-profile/${username}/`;
    const response = await axios.get(url);
    setDetails(response.data);
  };
  const handleLogout = () => {
    Cookies.remove("patient_token");
    Cookies.remove("patient_username");
    Cookies.remove("patient_status");
    Cookies.remove("staff");
    Cookies.remove("superuser");
    setIsLogin(null);
  };
  useEffect(() => {
    const patientUsername = Cookies.get("patient_username");
    const token = Cookies.get("patient_token");
    if (patientUsername !== undefined) {
      setIsLogin(token);
      fetchDetail();
    }
  }, [Login]);

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  setTimeout(() => {
    setDropdownOpen(false);
  }, 10000);

  const handleBookAppointment = () => {
    const token = Cookies.get("patient_token");
    if (!token) {
      Swal.fire({
        title: "You are not logged in!",
        text: "Would you like to continue as a guest or log in?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue as Guest",
        cancelButtonText: "Log In",
      }).then((result) => {
        if (result.isConfirmed) {
          setModalOpen(true);
        } else if (result.isDismissed) {
          navigate("/user/login");
        }
      });
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div className="bg-gray-200">
      <div className="container mx-auto px-4 sm:px-8 lg:px-32 xl:px-48">
        <div className="flex items-center justify-between h-24 sm:h-32">
          {data && (
            <div className="!w-2/5 md:!w-auto">
              <Link to="/">
                <img src={data.new_logo} alt="Logo" />
              </Link>
            </div>
          )}
          {/* Navigation links hidden on medium screens */}
          <nav className="hidden lg:flex flex-grow justify-center space-x-5">
            <Link
              to="/"
              onClick={() => handleTabClick("home")}
              className={`font-medium ${
                activeTab === "home" || active === "/"
                  ? "text-blue-800"
                  : "text-black"
              }`}
            >
              Home
            </Link>
            <Link
              to="/about"
              onClick={() => handleTabClick("about")}
              className={`font-medium ${
                activeTab === "about" || active === "/about"
                  ? "text-blue-800"
                  : "text-black"
              }`}
            >
              About Us
            </Link>
            <Link
              to="/services"
              onClick={() => handleTabClick("services")}
              className={`font-medium ${
                activeTab === "services" || active === "/services"
                  ? "text-blue-800"
                  : "text-black"
              }`}
            >
              Services
            </Link>
            <Link
              to="/blog"
              onClick={() => handleTabClick("blog")}
              className={`font-medium ${
                activeTab === "blog" || active === "/blog"
                  ? "text-blue-800"
                  : "text-black"
              }`}
            >
              Blog
            </Link>
            <Link
              to="/contactus"
              onClick={() => handleTabClick("contactus")}
              className={`font-medium ${
                activeTab === "contactus" || active === "/contactus"
                  ? "text-blue-800"
                  : "text-black"
              }`}
            >
              Contact Us
            </Link>
          </nav>
          <div className="hidden lg:flex flex-shrink-0">
            <Link
              type="button"
              className="py-2 px-3 bg-[#1030A4] text-white rounded-[5px] hover:bg-blue-700 font-semibold"
              onClick={handleBookAppointment}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Book Appointment
            </Link>
          </div>

          <div className="flex flex-col relative" ref={dropdownRef}>
            <Link onClick={() => setDropdownOpen(!dropdownOpen)} className="">
              <div className="hidden lg:flex items-center gap-1.5 px-2 bg-white rounded-lg ml-2  py-1.5">
                {isLogin === null ? (
                  <>
                    <FaUserCircle className="text-[35px] text-gray-700" />
                    {/* <text className="font-bold">Guest</text> */}
                  </>
                ) : (
                  <>
                    <img
                      className="h-[35px] w-[35px] rounded-full object-cover"
                      src={details.image}
                      alt=""
                    />
                    <text className="font-bold text-center w-fit">
                      {details.name.split(" ")[0]}
                    </text>
                  </>
                )}
              </div>
            </Link>
            {dropdownOpen && (
              <div className="mt-1 absolute z-10 right-0 top-[50px] rounded-lg w-48 bg-gray-100 shadow-lg">
                {isLogin === null ? (
                  <Link
                    to="/user/login"
                    className="block px-4 py-2 font-semibold text-[#113C54] text-center rounded-lg hover:bg-gray-300"
                    onClick={() => "myprofile"}
                  >
                    Login/Register
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/userprofile"
                      className="block px-4 py-2 font-semibold text-[#113C54] rounded-t-lg hover:bg-gray-300"
                      onClick={() => "myprofile"}
                    >
                      Profile
                    </Link>
                    <hr className="text-black-800 border-[2px] mx-4" />
                    <Link
                      to="/userappointments"
                      className="block px-4 py-2 font-semibold text-[#113C54] hover:bg-gray-300"
                      onClick={() => "myprofile"}
                    >
                      Appointments
                    </Link>
                    <hr className="text-black-800 border-[2px] mx-4" />
                    <Link
                      to="/userdocuments"
                      className="block px-4 py-2 font-semibold text-[#113C54] hover:bg-gray-300"
                      onClick={() => "myprofile"}
                    >
                      Documents
                    </Link>
                    <hr className="text-black-800 border-[2px] mx-4" />
                    <Link
                      to="/passwordchange"
                      className="block px-4 py-2 font-semibold text-[#113C54] hover:bg-gray-300"
                      onClick={() => "myprofile"}
                    >
                      Change Password
                    </Link>
                    <hr className="text-black-800 border-[2px] mx-4" />
                    <Link
                      to="/user/login"
                      className="block px-4 py-2 font-semibold text-[#113C54] rounded-b-lg hover:bg-gray-300"
                      onClick={() => handleLogout()}
                    >
                      Log Out
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
          <AppointmentModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
          {/* Menu button visible on medium and small screens */}
          <div className="lg:hidden flex-shrink-0">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="text-xl text-gray-700 hover:text-gray-900 focus:outline-none mr-4"
            >
              {showMenu ? <GrClose /> : <GiHamburgerMenu />}
            </button>
          </div>
        </div>
        {/* Mobile menu dropdown */}
        {showMenu && (
          <div className="lg:hidden bg-gray-200 shadow-md py-2 px-4">
            <nav className="flex flex-col space-y-2">
              <Link
                to="/"
                onClick={() => handleTabClick("home")}
                className={`font-medium ${
                  activeTab === "home" || active === "/"
                    ? "text-blue-800"
                    : "text-black"
                }`}
              >
                Home
              </Link>
              <Link
                to="/about"
                onClick={() => handleTabClick("about")}
                className={`font-medium ${
                  activeTab === "about" || active === "/about"
                    ? "text-blue-800"
                    : "text-black"
                }`}
              >
                About Us
              </Link>
              <Link
                to="/services"
                onClick={() => handleTabClick("services")}
                className={`font-medium ${
                  activeTab === "services" || active === "/services"
                    ? "text-blue-800"
                    : "text-black"
                }`}
              >
                Services
              </Link>
              <Link
                to="/blog"
                onClick={() => handleTabClick("blog")}
                className={`font-medium ${
                  activeTab === "blog" || active === "/blog"
                    ? "text-blue-800"
                    : "text-black"
                }`}
              >
                Blog
              </Link>
              <Link
                to="/contactus"
                onClick={() => handleTabClick("contactus")}
                className={`font-medium ${
                  activeTab === "contactus" || active === "/contactus"
                    ? "text-blue-800"
                    : "text-black"
                }`}
              >
                Contact Us
              </Link>
              {isLogin === null ? (
                <Link
                  to="/user/login"
                  className="block px-4 py-2 font-semibold text-[#2c97d1] text-center rounded-lg hover:bg-gray-300"
                  onClick={() => "myprofile"}
                >
                  Login/Register
                </Link>
              ) : (
                <>
                  <p className="text-[#113C54] text-xl font-bold">
                    User Settings
                  </p>
                  <Link
                    to="/userprofile"
                    className="block py-2 font-semibold text-[#2c97d1] rounded-t-lg hover:bg-gray-300"
                    onClick={() => "myprofile"}
                  >
                    Profile
                  </Link>
                  <hr className="text-black-800 border-[2px] mr-8" />
                  <Link
                    to="/userappointments"
                    className="block py-2 font-semibold text-[#2c97d1] hover:bg-gray-300"
                    onClick={() => "myprofile"}
                  >
                    Appointments
                  </Link>
                  <hr className="text-black-800 border-[2px] mr-8" />
                  <Link
                    to="/userdocuments"
                    className="block py-2 font-semibold text-[#2c97d1] hover:bg-gray-300"
                    onClick={() => "myprofile"}
                  >
                    Documents
                  </Link>
                  <hr className="text-black-800 border-[2px] mr-8" />
                  <Link
                    to="/passwordchange"
                    className="block py-2 font-semibold text-[#2c97d1] hover:bg-gray-300"
                    onClick={() => "myprofile"}
                  >
                    Change Password
                  </Link>
                  <hr className="text-black-800 border-[2px] mr-8" />
                  <Link
                    to="/user/login"
                    className="block py-2 font-semibold text-[#2c97d1] rounded-b-lg hover:bg-gray-300"
                    onClick={() => handleLogout()}
                  >
                    Log Out
                  </Link>
                </>
              )}

              <button
                type="button"
                className="py-2 px-3 bg-[#1030A4] text-white rounded-[5px] hover:bg-blue-700 font-semibold text-center"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={handleBookAppointment}
              >
                Book an Appointment
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
